import logo_icon_64 from '../assets/images/logo-icon-64.png';
import React from 'react'
import { Link } from "react-router-dom";

export default function Footer() {

    return (
        <>
            <footer className="footer bg-slate-950 relative text-gray-200 dark:text-gray-200">
                <div className="container">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12">
                            <div className="py-[60px] px-0">
                                <div className="grid grid-cols-1">
                                    <div className="text-center">
                                        <img src={logo_icon_64} className="block mx-auto" alt="" />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-[30px] px-0 border-t border-slate-800">
                    <div className="container text-center">
                        <div className="grid md:grid-cols-12 items-center">
                            <div className="md:col-span-6">
                                <div className="md:text-start text-center">
                                    <p className="text-gray-400">©
                                        {new Date().getFullYear()} Durrani Lab Pvt. Ltd. Design & Develop with <i
                                            className="mdi mdi-heart text-red-700"/> by <Link to="https://durranilab.com/"
                                                target="_blank" className="text-reset">Durrani Lab</Link>.
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
