import image from '../assets/images/client/01.png';
import image1 from '../assets/images/client/05.png';
import image2 from '../assets/images/client/02.png';
import image3 from '../assets/images/client/04.png';
import image4 from '../assets/images/client/03.png';
import image5 from '../assets/images/client/06.png';
import React from 'react';
import { Link } from "react-router-dom";

export default function ClientSreview() {
    return (
        <>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="testi">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-2xl text-xl font-medium">Client's Review</h3>
                        <p className="text-slate-400 max-w-xl mx-auto">Our clients' words speak volumes about our commitment to excellence. Hear what they have to say about the transformative impact of our solutions and unwavering dedication to their success.</p>
                    </div>

                    <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        <ul className="space-y-8">
                            <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={image}
                                         className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />
                                    <div className="ps-4">
                                        <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">Ravi Kumar</Link>
                                        <p className="text-slate-400">Managing Director, TailorPro India</p>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-slate-400">Durrani Lab transformed our tailoring business with their SaaS platform. Their attention to detail and understanding of our needs were impressive. Managing orders, invoices, and customer measurements has never been easier. Highly recommended!</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>
                            <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={image1}
                                         className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />
                                    <div className="ps-4">
                                        <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">Aisha Al-Mansouri</Link>
                                        <p className="text-slate-400">CEO, Gulf SecureNet</p>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-slate-400">Working with Durrani Lab was a game-changer for our cybersecurity solutions. Their expertise in developing a custom VPN for our clients across the Gulf region ensured top-notch security and seamless user experience. Their dedication to quality is unparalleled.</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>

                        <ul className="space-y-8 hidden sm:block">
                            <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={image2}
                                         className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />
                                    <div className="ps-4">
                                        <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">Li Wei</Link>
                                        <p className="text-slate-400">COO, AsiaTech Innovations</p>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-slate-400">We partnered with Durrani Lab to develop our mobile app, and the results exceeded our expectations. Their technical knowledge and ability to deliver on time were crucial for our project’s success in the Asian market. Excellent work!</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>
                            <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={image3}
                                         className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />
                                    <div className="ps-4">
                                        <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">Sameer Khan</Link>
                                        <p className="text-slate-400">Founder, Arabian Digital Ventures</p>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-slate-400">Durrani Lab’s tailored solutions helped us scale our business effortlessly. Their custom CRM has streamlined our operations, and their ongoing support is always prompt and effective. We couldn’t be happier with the partnership.</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>

                        <ul className="space-y-8 hidden lg:block">
                            <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={image4}
                                         className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />
                                    <div className="ps-4">
                                        <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">Priya Desai</Link>
                                        <p className="text-slate-400">Head of Operations, TextileSoft Solutions</p>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-slate-400">The team at Durrani Lab brought our vision to life with their SaaS platform for the textile industry. Their deep understanding of our business needs and commitment to excellence made all the difference. We highly recommend their services.</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>

                            <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={image5}
                                         className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />
                                    <div className="ps-4">
                                        <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">Yusuf Ahmed</Link>
                                        <p className="text-slate-400">Director, GulfTech Solutions</p>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <p className="text-slate-400">Durrani Lab's expertise in developing our custom WireGuard VPN client was instrumental in enhancing our security protocols. Their deep technical knowledge and responsive service have set a new standard for excellence in our region. We are thrilled with the results!</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}
