import React from 'react'
import {Link} from "react-router-dom";

export default function GetInTouch() {
    return (
        <>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium">Get In Touch !</h3>
                        <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Ready to start your digital
                            journey with us? Contact our experts today, and let's turn your ideas into reality.</p>
                    </div>
                    <div className="grid grid-cols-4 lg:grid-cols-4 md:grid-cols-2 mt-8 items-center gap-[30px]">


                        <div className="flex">
                            <div className="icons text-center mx-auto">
                                <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"/>
                            </div>

                            <div className="flex-1 ms-6">
                                <h5 className="text-lg dark:text-white mb-2 font-medium">Phone</h5>
                                <Link to="tel:+919359437534" className="text-slate-400">+91 9359437534</Link>
                            </div>
                        </div>

                        <div className="flex mt-4">
                            <div className="icons text-center mx-auto">
                                <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"/>
                            </div>

                            <div className="flex-1 ms-6">
                                <h5 className="text-lg dark:text-white mb-2 font-medium">Headquarters</h5>
                                <p className="text-slate-400 mb-2">#628 Rehab Bldg, Crescent Royale, Andheri West, Mumbai,
                                    India.</p>
                            </div>
                        </div>

                        <div className="flex mt-4">
                            <div className="icons text-center mx-auto">
                                <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"/>
                            </div>

                            <div className="flex-1 ms-6">
                                <h5 className="text-lg dark:text-white mb-2 font-medium">Email</h5>
                                <Link to="mailto:contact@durranilab.com"
                                      className="text-slate-400">contact@durranilab.com</Link>
                            </div>
                        </div>



                        <div className="flex mt-4">
                            <div className="icons text-center mx-auto">
                                <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"/>
                            </div>

                            <div className="flex-1 ms-6">
                                <h5 className="text-lg dark:text-white mb-2 font-medium">Office Location</h5>
                                <p className="text-slate-400 mb-2">#1-2 Jamadar Hospital Bldg., Khori Galli, Latur MH
                                    India.</p>
                            </div>
                        </div>


                    </div>
                </div>

            </section>
        </>
    )
}
