import React from 'react';
import { Link } from "react-router-dom";
import { Hexagon } from 'react-feather';

export default function OurServices() {
    const services = [
        {
            icon: 'adjust-circle',
            title: 'Custom Software Development',
            description: "Crafting tailor-made software solutions to meet unique business needs.",
        },
        {
            icon: 'globe',
            title: 'Website Design and Development',
            description: "Creating visually stunning and user-friendly websites for businesses and organizations.",
        },
        {
            icon: 'box',
            title: 'Mobile App Development',
            description: "Developing mobile applications for iOS and Android platforms.",
        },
        {
            icon: 'shopping-cart',
            title: 'E-commerce Solutions',
            description: "Building online stores and enabling secure online transactions.",
        },
        {
            icon: 'window',
            title: 'UI/UX Design',
            description: "Designing intuitive and engaging user interfaces for applications and websites.",
        },
        {
            icon: 'server',
            title: 'Web Hosting and Maintenance',
            description: "Providing hosting services and ongoing website maintenance and updates.",
        },
        {
            icon: 'monitor',
            title: 'Digital Marketing',
            description: "Promoting online presence through strategies like social media marketing and pay-per-click advertising.",
        },
        {
            icon: 'cloud',
            title: 'Cloud Solutions',
            description: "Leveraging cloud computing for scalability and efficiency.",
        },
        {
            icon: 'shield',
            title: 'Cybersecurity Services',
            description: "Ensuring the security of websites and applications against cyber threats.",
        }
    ];
    return (
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="features">
            <div className="container lg mx-auto">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-2xl text-xl font-medium">Our Services</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">
                        Discover a spectrum of services tailored to your needs, from bespoke software development to captivating website design. Let us turn your vision into reality with precision and flair.
                    </p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                    {services.map((service, index) => (
                        <div key={index} className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                            <div className="relative overflow-hidden text-transparent -m-3">
                                <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"
                                />
                                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                    <i className={`uil uil-${service.icon}`}/>
                                </div>
                            </div>
                            <div className="mt-6">
                                <Link to="" className="text-lg h5 transition duration-500 ease-in-out hover:text-violet-600" >
                                    {service.title}
                                </Link>
                                <p className="text-slate-400 transition duration-500 ease-in-out mt-3">
                                    {service.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
};
